import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
const Container1PisoColumnas = (props) => {
  const { link, sectionClass = "", title, title1, link1, title2, link2, title3, link3, title4, link4, linkImage } = props;
  const defaultTemplate = ["OnexOneColumn", "Half", "Half", "OnexOneColumn", "Half", "Half"];
  const items = [];
  for (let index = 0; index < 6; index++) {
    items.push(
      <Card className={`div${index + 1}`} key={`container${index + 1}`}>
        {props.renderContainerWidgetCallback(
          index + 1,
          props.positionProperties?.[index]?.templateNews ?? defaultTemplate[index], { containerName: "contenedor_1_piso_columnas"}
        )}
      </Card>
    );
  }

  const marfeelTitle = title ? "container1PisoColumnas-"+title?.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/\s+/g, '-')
  .toLowerCase() : "container1PisoColumnas";

  const titlesExtras = [];
  if(title1 && link1) titlesExtras.push({title: title1, link: link1})
  if(title2 && link2) titlesExtras.push({title: title2, link: link2})
  if(title3 && link3) titlesExtras.push({title: title3, link: link3})
  if(title4 && link4) titlesExtras.push({title: title4, link: link4})

  return (
    props.content?.length > 0 && <>
      {title && <Separator title={title} link={link} sectionClass={sectionClass} extras={titlesExtras} linkImage={linkImage}/>}
      <GridStyleColor id={marfeelTitle}>
        <ParentGrid data-mrf-recirculation={`${marfeelTitle}`} className={`container1PisoColumnas module-grid ${sectionClass}`}>
          {items}
        </ParentGrid>
      </GridStyleColor>
    </>
  );
};
export default Container1PisoColumnas;